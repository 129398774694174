import { mergeClassNames } from '@hitechline/reactools';
import { useState, useCallback, useMemo } from 'react';

import { LicensedButton } from '@/src/screen/components/ui/LicensedButton';
import { SuportButton } from '@/src/screen/components/ui/SuportButton';
import Logo from '@means/assets/png/logo.png';
import Hamburger from '@means/assets/svg/hamburger.svg';
import { links } from '@means/data/header';
import { Link } from '@screen/components/forward/Link';
// import { DriverButton } from '@screen/components/ui/DriverButton';
import { BlockScroll } from '@screen/styles/js/BlockScroll';

import styles from './styles.module.css';

export const Mobile = (): JSX.Element => {
  const [dropdownVisible, updateDropdownVisible] = useState(false);

  const closeDropdown = useCallback(() => {
    updateDropdownVisible(false);
  }, []);

  const handleDropdown = useCallback(() => {
    updateDropdownVisible(oldValue => !oldValue);
  }, []);

  const dropdown = useMemo(
    () => (
      <div className={styles.dropdown}>
        <div className="overflow-y-auto pretty-scrollbar bowl-content py-24 flex flex-col h-full items-center ">
          <nav className="flex flex-col align-center uppercase space-y-14 text-center text-xl font-medium">
            {links.map(({ href, title }) => (
              <Link key={href} href={href} onClick={closeDropdown}>
                {title}
              </Link>
            ))}
            <SuportButton />
          </nav>

          <div className="mt-auto pt-16">
            {/* <DriverButton /> */} <LicensedButton />{' '}
          </div>
        </div>
      </div>
    ),
    [closeDropdown],
  );

  return (
    <div
      className={mergeClassNames(styles.wrapper, {
        'dropdown-visible': dropdownVisible,
      })}
    >
      <div className="bowl-content flex justify-between h-full items-center">
        <button type="button" onClick={handleDropdown} className="flex">
          <Hamburger className="w-8 h-auto" />
        </button>

        <Link href="/">
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            src={Logo.src}
            alt="Logo - Urbano Institucional"
            className="w-10"
          />
        </Link>
      </div>

      {dropdownVisible && (
        <>
          {dropdown}
          <BlockScroll />
        </>
      )}
    </div>
  );
};
