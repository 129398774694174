import { SignIn } from 'phosphor-react';

import { Link } from '@/screen/components/forward/Link';
// import { useUI } from '@modules/hooks/useUI';

import styles from './styles.module.css';

export const LicensedButton = (): JSX.Element => {
  return (
    <Link
      href="https://cessionarios.urbanonorte.app/"
      className={styles.container}
    >
      <span className="mr-2">Licenciados</span>
      <SignIn size={23} />
    </Link>
  );
};
