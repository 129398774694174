import Facebook from '@means/assets/svg/fb-icon.svg';
import Instagram from '@means/assets/svg/ig-icon.svg';
import Twitter from '@means/assets/svg/tw-icon.svg';
import { Link } from '@screen/components/forward/Link';

import styles from './styles.module.css';

export const CopyrightAndSocial = (): JSX.Element => (
  <div className="bowl-content">
    <div className={styles.content}>
      <div className="flex space-x-6">
        <Link href="https://www.facebook.com/UrbanoNorteBR">
          <Facebook />
        </Link>

        <Link href="https://www.instagram.com/urbanonortebrasil">
          <Instagram />
        </Link>

        <Link href="https://twitter.com/urbanonorte">
          <Twitter />
        </Link>
      </div>

      <p className="text-sm sm:text-center">
        © Urbano Norte — Todos os direitos reservados
      </p>

      <div className="text-sm flex flex-col sm:ml-auto">
        <Link href="/polices/privacy">Política de Privacidade</Link>
        <Link href="/polices/terms">Termos de Uso</Link>
      </div>
    </div>
  </div>
);
