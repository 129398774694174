import Appstore from '@means/assets/png/appstore.png';
import Playstore from '@means/assets/png/playstore.png';
import { APP_PLAYSTORE_URL, APP_APPSTORE_URL } from '@means/data/constants';

export const Stores = (): JSX.Element => (
  <div className="flex items-center flex-col bowl-content mt-14">
    <h1 className="font-bold">DISPONÍVEL EM</h1>

    <div className="flex justify-center flex-wrap mt-2 items-center gap-8">
      <a target="_blank" href={APP_PLAYSTORE_URL} rel="noreferrer">
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img src={Playstore.src} alt="Playstore" />
      </a>

      <a target="_blank" href={APP_APPSTORE_URL} rel="noreferrer">
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img src={Appstore.src} alt="Playstore" />
      </a>
    </div>
  </div>
);
