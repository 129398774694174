module.exports = {
  mode: 'jit',
  purge: ['./src/**/*.{js,ts,jsx,tsx}'],
  future: {
    applyComplexClasses: true,
    purgeLayersByDefault: true,
  },
  theme: {
    extend: {
      backgroundSize: {
        full: '100%',
      },
      padding: {
        '1-5': '0.2rem',
      },
      maxWidth: {
        qhd: '86rem',
      },
    },

    fontFamily: {
      sans: [
        'Poppins',
        'system-ui',
        '-apple-system',
        'sans-serif',
        'Helvetica',
      ],
    },
    variables: {
      DEFAULT: {
        wai: {
          'min-height': '2.8rem',
        },
        colors: {
          'blue-rgb': '91, 165, 240',
          'purple-rgb': '108, 51, 158',
        },
      },
    },
    colors: {
      // Favorite
      favorite: 'hsl(107, 36%, 95%)',

      // Background
      floor: {
        black: 'hsl(0, 0%, 0%)',
        white: 'hsl(0, 0%, 100%)',
        green: {
          DEFAULT: 'hsl(153, 100%, 30%)',

          300: 'hsl(138, 56%, 55%)',
          900: 'hsl(138, 65%, 26%)',
        },
        grey: {
          DEFAULT: 'hsl(0, 0%, 89%)',

          100: 'hsl(0, 0%, 96%)',
        },
        dark: {
          DEFAULT: 'hsl(44, 0%, 2%)',
          300: 'hsl(340, 3%, 21%)',
          400: 'hsl(215, 1%, 17%)',
          500: 'hsl(0, 0%, 13%)',
          600: 'hsl(120, 3%, 7%)',
          700: 'hsl(0, 0%, 7%)',
          800: 'hsl(0, 0%, 5%)',
        },
      },

      // Typography
      typography: {
        white: 'hsl(0, 0%, 100%)',
        green: 'hsl(153, 100%, 30%)',
        red: 'hsl(0, 100%, 65%)',

        black: {
          DEFAULT: 'hsl(0, 0%, 13%)',
          full: 'hsl(0, 0%, 0%)',
        },
        grey: {
          DEFAULT: 'hsl(0, 0%, 53%)',
          light: 'hsl(257, 0%, 68%)',
          strong: 'hsl(218, 23%, 23%)',
          medium: 'hsl(340, 3%, 21%)',
        },
      },

      // All
      transparent: 'transparent',
    },
  },
  plugins: [require('@mertasan/tailwindcss-variables')],
};
