import { createContext, useRef, useCallback } from 'react';

import {
  DriverModal,
  ModalHandles,
} from '@screen/components/modals/DriverModal';
import { SuportModal } from '@screen/components/modals/SuportModal';

export interface UIContextData {
  loading: boolean;
  openDriverModal(): void;
  closeDriverModal(): void;

  openSuportModal(): void;
  closeSuportModal(): void;
}

export const UIContext = createContext({} as UIContextData);

export const UIProvider = ({ children }: PropsWithChildren): JSX.Element => {
  const driverModalRef = useRef<ModalHandles>(null);
  const suportModalRef = useRef<ModalHandles>(null);

  const openDriverModal = useCallback(() => {
    driverModalRef.current?.open();
  }, []);

  const closeDriverModal = useCallback(() => {
    driverModalRef.current?.close();
  }, []);

  const openSuportModal = useCallback(() => {
    suportModalRef.current?.open();
  }, []);

  const closeSuportModal = useCallback(() => {
    suportModalRef.current?.close();
  }, []);

  return (
    <UIContext.Provider
      value={{
        loading: false,
        openDriverModal,
        closeDriverModal,
        openSuportModal,
        closeSuportModal,
      }}
    >
      <DriverModal ref={driverModalRef} />
      <SuportModal ref={suportModalRef} />

      {children}
    </UIContext.Provider>
  );
};
