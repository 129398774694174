/* eslint-disable import/no-extraneous-dependencies */
import { BiSupport } from 'react-icons/bi';

import { useUI } from '@modules/hooks/useUI';
import { BaseButton } from '@screen/components/ui/BaseButton';

import styles from './styles.module.css';

export const SuportButton = (): JSX.Element => {
  const { openSuportModal } = useUI();

  return (
    <BaseButton onClick={openSuportModal} className={styles.container}>
      <span className="mr-2">Suporte</span>
      <BiSupport className="w-4 h-auto" />
    </BaseButton>
  );
};
