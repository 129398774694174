import NextLink, { LinkProps } from 'next/link';
import { useMemo, HTMLAttributes } from 'react';

import { parseUrl } from './parseUrl';

interface Props extends LinkProps, HTMLAttributes<HTMLElement> {}

export const Link = ({
  children,
  ...props
}: PropsWithChildren<Props>): JSX.Element => {
  const href = useMemo(
    () => parseUrl(props.as) || parseUrl(props.href),
    [props.as, props.href],
  );

  return (
    <NextLink {...props}>
      <a {...props} href={href}>
        {children}
      </a>
    </NextLink>
  );
};
