import { mergeRefs } from '@hitechline/reactools';
import { forwardRef, useRef, useCallback, Ref } from 'react';

import Playstore from '@means/assets/png/playstore.png';
import X from '@means/assets/svg/x.svg';
import { DRIVER_APP_PLAYSTORE_URL } from '@means/data/constants';
import { Modal, ModalHandles } from '@screen/components/forward/Modal';

import styles from './styles.module.css';

export const DriverModal = forwardRef(
  (_props: any, ref: Ref<ModalHandles>): JSX.Element => {
    const modalRef = useRef<ModalHandles>(null);

    const close = useCallback(() => {
      modalRef.current?.close();
    }, [modalRef]);

    return (
      <Modal className={styles.wrapper} ref={mergeRefs([ref, modalRef])}>
        <div className={styles.container}>
          <div className={styles.header}>
            <button type="button" onClick={close}>
              <X />
            </button>
          </div>

          <div className="w-full text-center overflow-y-auto spacing-x py-6">
            <p className="text-center">
              Torne-se um motorista <strong>Urbano Norte</strong>.
            </p>
            <p>
              Baixe o app{' '}
              <span className="underline">Urbano Norte Motorista</span> nas
              lojas disponíveis
            </p>

            <div className="flex justify-center flex-wrap mt-2 items-center gap-8">
              <a
                target="_blank"
                href={DRIVER_APP_PLAYSTORE_URL}
                rel="noreferrer"
              >
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img src={Playstore.src} alt="Playstore" />
              </a>
            </div>
          </div>
        </div>
      </Modal>
    );
  },
);

DriverModal.displayName = 'DriverModal';
