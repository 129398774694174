import type { TailwindConfig } from 'tailwindcss/tailwind-config';

import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '@/tailwind.config';

type MainConfig = typeof tailwindConfig;

let storedConfig: TailwindConfig | null = null;

function getConfig(): AllRequired<TailwindConfig & MainConfig> {
  if (!storedConfig) {
    storedConfig = resolveConfig(<any>tailwindConfig) as any;
  }

  return storedConfig as any as AllRequired<TailwindConfig & MainConfig>;
}

export default getConfig();
