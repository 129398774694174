import { Link } from '@screen/components/forward/Link';

export const Links = (): JSX.Element => (
  <div className="bowl-content mt-14 flex flex-wrap gap-12">
    <div>
      <h1 className="text-1xl text-typography-grey">Empresa</h1>

      <nav className="flex flex-col text-sm mt-2">
        <Link href="/#" className="hover:underline">
          Quem Somos
        </Link>
        <Link href="/#" className="hover:underline">
          Segurança
        </Link>
        <Link href="/#" className="hover:underline">
          Motoristas
        </Link>
        <Link href="/#" className="hover:underline">
          Blog
        </Link>
      </nav>
    </div>

    <div>
      <h1 className="text-1xl text-typography-grey">Produtos</h1>

      <nav className="flex flex-col text-sm mt-2">
        <Link href="/#" className="hover:underline">
          Urbano Viagens
        </Link>
        <Link href="/#" className="hover:underline">
          Urbano Entregas
        </Link>
      </nav>
    </div>
  </div>
);
