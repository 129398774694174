/* eslint-disable import/no-extraneous-dependencies */
import { mergeRefs } from '@hitechline/reactools';
import { forwardRef, useRef, useCallback, Ref } from 'react';
import { AiOutlineCar } from 'react-icons/ai';
import { BiPhoneCall } from 'react-icons/bi';

import X from '@means/assets/svg/x.svg';
import { Modal, ModalHandles } from '@screen/components/forward/Modal';

import styles from './styles.module.css';

export const SuportModal = forwardRef(
  (_props: any, ref: Ref<ModalHandles>): JSX.Element => {
    const modalRef = useRef<ModalHandles>(null);

    const close = useCallback(() => {
      modalRef.current?.close();
    }, [modalRef]);

    return (
      <Modal className={styles.wrapper} ref={mergeRefs([ref, modalRef])}>
        <div className={styles.container}>
          <div className={styles.header}>
            <button type="button" onClick={close}>
              <X />
            </button>
          </div>

          <div className="w-full text-center overflow-y-auto spacing-x py-6">
            <h1 className="font-bold text-3xl">Suporte Urbano Norte</h1>
            <p className="text-xl">
              Precisa de ajuda? Fale conosco através dos{' '}
              <strong>canais de atendimento.</strong>
            </p>
            <section className="flex items-center justify-center gap-3 mt-6 ">
              <a href="https://wa.me/556999580008">
                <div className="bg-floor-grey p-6 flex items-center flex-col rounded-lg hover:bg-floor-dark hover:text-typography-white duration-200">
                  <AiOutlineCar color="#009B54" size="30" />
                  <p className="font-bold mt-4">Alô Motorista</p>
                </div>
              </a>
              <a href="https://wa.me/556993968808">
                <div className="bg-floor-grey p-6 flex items-center flex-col rounded-lg hover:bg-floor-dark hover:text-typography-white duration-200">
                  <BiPhoneCall color="#009B54" size="30" />
                  <p className="font-bold mt-4">Alô Passageiro</p>
                </div>
              </a>
            </section>
          </div>
        </div>
      </Modal>
    );
  },
);

SuportModal.displayName = 'SuportModal';
