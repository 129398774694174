import { CopyrightAndSocial } from './atoms/CopyrightAndSocial';
import { Links } from './atoms/Links';
import { Main } from './atoms/Main';
import { PoweredBy } from './atoms/PoweredBy';
import { Stores } from './atoms/Stores';

export const Footer = (): JSX.Element => (
  <footer className="bg-floor-dark-600 text-typography-white py-14">
    <Main />
    <Links />
    <Stores />
    <CopyrightAndSocial />
    <PoweredBy />
  </footer>
);
