import { LicensedButton } from '@/src/screen/components/ui/LicensedButton';
import { SuportButton } from '@/src/screen/components/ui/SuportButton';
import Logo from '@means/assets/png/logo.png';
import { links } from '@means/data/header';
import { Link } from '@screen/components/forward/Link';

// import { DriverButton } from '@screen/components/ui/DriverButton';

import styles from './styles.module.css';

export const Desktop = (): JSX.Element => (
  <div className={styles.wrapper}>
    <div className="bowl-content py-2 grid items-center">
      <nav className="space-x-4 font-medium">
        {links.map(({ href, title }) => (
          <Link key={href} href={href} className="hover:underline">
            {title}
          </Link>
        ))}
      </nav>

      <Link href="/" className="flex items-center justify-center">
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          src={Logo.src}
          alt="Logo - Urbano Institucional"
          className="w-12 absolute"
        />
      </Link>

      <div className="flex justify-end gap-2">
        <SuportButton />
        <LicensedButton />
        {/* <DriverButton /> */}
      </div>
    </div>
  </div>
);
