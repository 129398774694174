export const IS_DEV = process.env.NODE_ENV !== 'production';

//

export const GA_TRACKING_ID = 'UA-210595665-1';

//

export const GTM_ID = 'AW-11084616075';

//

export const APP_URL = 'https://urbanonorte.app';
export const APP_PUBLIC_URL = IS_DEV ? process.env.PUBLIC_URL ?? '/' : APP_URL;

//

export const EMAIL_URL = 'mailto:contato@urbanonorte.app';
export const INSTAGRAM_URL = 'https://www.instagram.com';

//

export const APP_PLAYSTORE_URL =
  'https://play.google.com/store/apps/details?id=br.com.urbanonorte.passenger.drivermachine&hl=pt_BR&gl=US';
export const APP_APPSTORE_URL =
  'https://apps.apple.com/br/app/urbano-norte/id1244757393';

export const DRIVER_APP_PLAYSTORE_URL =
  'https://play.google.com/store/apps/details?id=br.com.urbanonorte.taxi.drivermachine&hl=pt_BR&gl=US';
