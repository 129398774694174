export const Main = (): JSX.Element => (
  <div className="bowl-content">
    <h1 className="text-2xl font-bold">URBANO NORTE</h1>

    <p className="text-1xl mt-2">
      URBANO NORTE TECNOLOGIA LTDA - 29.981.580/0001-27
    </p>
    <p>Rua Guiana, 3309 - Embratel, Porto Velho - RO</p>
    <p className="text-sm">
      <a href="mailto:sac@urbanonortematriz.com.br">
        sac@urbanonortematriz.com.br
      </a>
    </p>
  </div>
);
